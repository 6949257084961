import React from "react";
import Image from "gatsby-image";
import {MDBRow, MDBCol, MDBContainer} from "mdbreact";

const GalleryTemp = (props) => {
  return (
    <MDBContainer fluid className={"align-container"}>
      <MDBRow md="12">
        <MDBCol md="6">
          <Image
            className="image-temp-container1"
            fluid={props.images[1].childImageSharp.fluid}
          />
        </MDBCol>
        <MDBCol md="6">
          <MDBRow>
            <Image
              className="image-temp-container2"
              fluid={props.images[2].childImageSharp.fluid}
            />
          </MDBRow>
          <MDBRow>
            <Image
              className="image-temp-container3"
              fluid={props.images[3].childImageSharp.fluid}
            />
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default GalleryTemp;
