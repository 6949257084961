import React from "react";
import Image from "gatsby-image";
import {
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBCardText,
  MDBContainer,
} from "mdbreact";

const ViewCaseTemp = (props) => {
  return (
    <MDBContainer fluid>
      <MDBRow md="12" className="view-case">
        <MDBCol md="5" className="image-temp-container">
          <Image
            className="view-case-image-wrapper"
            fluid={props.images[0].childImageSharp.fluid}
            alt=""
          />
        </MDBCol>
        <MDBCol md="7">
          <MDBCardBody cascade className="view-case-container">
            <MDBCardTitle className="case-page-header-text">
              <span>Case study</span>
            </MDBCardTitle>
            <MDBCardTitle className="card-temp-title">
              {props.clientName}
            </MDBCardTitle>
            <div className="underline"></div>

            <MDBCardText className="card-temp-text">
              {props.description}
            </MDBCardText>
          </MDBCardBody>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ViewCaseTemp;
