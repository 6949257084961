import React from "react";
import Image from "gatsby-image";
import { MDBRow, MDBCol, MDBContainer, MDBCardText } from "mdbreact";
const LastPage = (props) => {
  return (
    <MDBContainer fluid className={"align-container"}>
      <MDBRow md="12">
        <MDBCol md="9">
          <div className="image-wrapper">
            <Image
              className="last-page-image"
              fluid={props.images[3].childImageSharp.fluid}
            />
          </div>
        </MDBCol>
        <MDBCol md="3">
          <MDBRow className="view-page-one">
            <MDBCardText tag="h1" className="next-text">
              <a href="/#Work" style={{ color: "black" }}>
                NEXT
              </a>
            </MDBCardText>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default LastPage;
