import React, { useState } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import { graphql } from "gatsby";
import FirstPage from "./ViewCaseTemp";
import SecondPage from "./ViewCasepagetwo";
import ThirdPage from "./ViewCasePageThree";
import FourthPage from "./GalleryTemp";
import FifthPage from "./LastPage";
import Navbar from "../components/navbar/Navbar";

// NOTE: if using fullpage extensions/plugins put them here and pass it as props
const pluginWrapper = () => {
  require("../fullpageJSExtensions/fullpage.dragAndMove.min"); // Optional. Required when using the "fadingEffect" extension.
  require("../fullpageJSExtensions/fullpage.scrollHorizontally.min"); // Optional. Required when using the "fadingEffect" extension.
};

const CaseStudyWrapper = ({ data }) => {
  const [pageIndex, setPageIndex] = useState(0);
  let onSlideLeave = (origin, destination, direction) => {
    setPageIndex(direction.index);
  };

  const navbarStyle = (number) => {
    if (number === 1) {
      return <Navbar styleClass="blackStyle" light={false} dark={true} />;
    } else {
      return <Navbar styleClass="whiteStyle" light={true} dark={true} />;
    }
  };

  const {
    allMarkdownRemark: { nodes },
  } = data;
  return (
    <ReactFullpage
      // Required when using extensions
      pluginWrapper={pluginWrapper}
      onSlideLeave={onSlideLeave.bind(this)}
      // fullpage options
      licenseKey={"E8013069-1FEF4426-A19B31C5-33E68BBC"} // Get one from https://alvarotrigo.com/fullPage/pricing/
      navigation
      anchors={["Page1", "Page2", "Page3", "Page4", "Page5", "Page6"]}
      dragAndMoveKey={
        "ZXRhbGVzLWNvcnBvcmF0ZS5uZXRsaWZ5LmFwcF84Q0NaSEpoWjBGdVpFMXZkbVU9WWh2"
      }
      dragAndMove={true}
      scrollHorizontallyKey={
        "ZXRhbGVzLWNvcnBvcmF0ZS5uZXRsaWZ5LmFwcF9mN2ZjMk55YjJ4c1NHOXlhWHB2Ym5SaGJHeDVDRTU="
      }
      scrollHorizontally={true}
      controlArrows={false}
      menu={true}
      autoScrolling={true}
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <div className="section">
              <div className="close-case">
                <div className="d-flex flex-row-reverse align-items-end close-case-container">
                  <a
                    href="/#Work"
                    className={"close-case-text"}
                    style={
                      pageIndex === 1 ? { color: "white" } : { color: "black" }
                    }
                  >
                    Close case
                  </a>
                </div>
              </div>
              {navbarStyle(pageIndex)}
              <div className={"slide"}>
                <FirstPage
                  images={nodes[0].frontmatter.clientImages}
                  clientName={nodes[0].frontmatter.clientTitle}
                  description={nodes[0].frontmatter.clientDescription}
                />
              </div>
              <div className={"slide"}>
                <SecondPage
                  clientName={nodes[0].frontmatter.clientTitle}
                  services={nodes[0].frontmatter.clientServices}
                  year={nodes[0].frontmatter.clientYear}
                  tools={nodes[0].frontmatter.clientToolsUsed}
                  images={nodes[0].frontmatter.clientImages}
                />
              </div>
              <div className={"slide"}>
                <ThirdPage
                  description={nodes[0].frontmatter.clientDescription2}
                />
              </div>
              <div className={"slide"}>
                <FourthPage images={nodes[0].frontmatter.clientImages} />
              </div>
              <div className={"slide"}>
                <ThirdPage
                  description={nodes[0].frontmatter.clientDescription3}
                />
              </div>
              <div className={"slide"}>
                <FifthPage images={nodes[0].frontmatter.clientImages} />
              </div>
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
};
export const query = graphql`
  query StudyCases($clientName: String) {
    allMarkdownRemark(
      filter: { frontmatter: { clientTitle: { eq: $clientName } } }
    ) {
      nodes {
        frontmatter {
          clientTitle
          clientDescription
          clientDescription2
          clientDescription3
          clientToolsUsed
          clientYear
          clientServices
          clientImages {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
export default CaseStudyWrapper;
