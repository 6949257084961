import React from "react";
import Image from "gatsby-image//withIEPolyfill";
import {
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBCardBody,
  MDBContainer,
} from "mdbreact";

function splitOnComma(text) {
  text = text.split(",");
  if (text.length > 1) {
    text = text.map((element, index) => {
      return index < text.length - 1 ? (
        <span key={element}>{element},</span>
      ) : (
        <span key={element}>{element}</span>
      );
    });
  }
  return text;
}

const ViewCaseTemp = (props) => {
  return (
    <MDBContainer fluid>
      <MDBRow md="12" className="view-page-two">
        <MDBCol md="7">
          <MDBCardBody cascade className="view-case-two-text">
            <MDBCardTitle className="case-two-header-text">Client</MDBCardTitle>
            <MDBCardTitle className="case-two-description">
              {splitOnComma(props.clientName)}
            </MDBCardTitle>
            <MDBCardTitle className="case-two-header-text">
              Services
            </MDBCardTitle>
            <MDBCardTitle className="case-two-description">
              {splitOnComma(props.services)}
            </MDBCardTitle>
            <MDBCardTitle className="case-two-header-text">Year</MDBCardTitle>
            <MDBCardTitle className="case-two-description">
              {splitOnComma(props.year)}
            </MDBCardTitle>
            <MDBCardTitle className="case-two-header-text">
              Tools used
            </MDBCardTitle>
            <MDBCardTitle className="case-two-description">
              {splitOnComma(props.tools)}
            </MDBCardTitle>
          </MDBCardBody>
        </MDBCol>
        <MDBCol md="4" className="">
          <div className="image-container-two">
            <Image
              className="image-two"
              fluid={props.images[1].childImageSharp.fluid}
            />
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

ViewCaseTemp.propTypes = {};

export default ViewCaseTemp;
