import React from "react";
import {MDBCardText, MDBRow, MDBCol, MDBContainer} from "mdbreact";

const ViewCasePageThree = (props) => {
  return (
    <MDBContainer fluid className={"align-container"}>
      <MDBRow>
        <MDBCol md="12">
          <MDBRow className="view-page-one">
            <MDBCardText className="view-page-three-text">
              {props.description}
            </MDBCardText>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
};

export default ViewCasePageThree;
